var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', [_c('b-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "10px"
    }
  }, [_c('h3', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.jadwal_name))]), _c('span', {
    staticClass: "d-block text-white"
  }, [_vm._v("Paket: " + _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.name))]), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center text-body mr-5"
  }, [_c('feather-icon', {
    staticClass: "mr-50 text-white",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v("Waktu Mulai")])], 1), _c('div', {
    staticClass: "d-flex align-items-center text-body ml-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50 text-white",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(" Waktu Berakhir ")])], 1)]), _c('div', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [(_vm$data3 = _vm.data) !== null && _vm$data3 !== void 0 && _vm$data3.info_start ? _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(" " + _vm._s(_vm.getTanggal(_vm.data.info_start)) + " WIB ")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center text-body ml-1"
  }, [(_vm$data4 = _vm.data) !== null && _vm$data4 !== void 0 && _vm$data4.info_end ? _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(" " + _vm._s(_vm.getTanggal(_vm.data.info_end)) + " WIB ")]) : _vm._e()])]), _c('div', {
    staticClass: "mt-3"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 mb-1",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.mulai
    }
  }, [_c('span', [_vm._v("Mulai Tryout")])])], 1)])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('b-row', {}, [_c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Peserta")]), _c('br'), _c('b', [_vm._v(_vm._s(_vm.data.sum_quota) + "/" + _vm._s(_vm.data.max_quota))])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Total Waktu")]), _c('br'), _c('b', [_vm._v(_vm._s(_vm.totalTimer) + " menit")])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Total Soal")]), _c('br'), _c('b', [_vm._v(_vm._s(_vm.totalSoal) + " Soal")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary",
      "header": 'Subtest (' + _vm.getDataSubtest() + ')',
      "header-bg-variant": "primary",
      "header-text-variant": "white"
    }
  }, [_c('b-card-body', [_vm.dataSubtes.length > 0 ? _c('app-collapse', _vm._l(_vm.dataSubtes, function (data, i) {
    var _data$ujian_data, _data$ujian_data2;
    return _c('div', {
      key: i
    }, [data.name && data.items ? _c('app-collapse-item', {
      attrs: {
        "title": data.name
      }
    }, [_c('ul', {
      staticStyle: {
        "padding": "0"
      }
    }, _vm._l(data.items, function (items, i) {
      var _items$ujian_data, _items$ujian_data2;
      return _c('li', {
        key: i,
        staticClass: "mt-1",
        staticStyle: {
          "border-bottom": "3px solid #e83b07"
        }
      }, [_c('b', [_vm._v(_vm._s(items.nama_ujian))]), _c('br'), _c('section', {
        staticClass: "pb-1"
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ClockIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(items.timer) + " Menit ")]), _c('feather-icon', {
        staticClass: "mr-50 ml-2",
        attrs: {
          "icon": "FileTextIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(items !== null && items !== void 0 && (_items$ujian_data = items.ujian_data) !== null && _items$ujian_data !== void 0 && _items$ujian_data.sum_soal ? items === null || items === void 0 ? void 0 : (_items$ujian_data2 = items.ujian_data) === null || _items$ujian_data2 === void 0 ? void 0 : _items$ujian_data2.sum_soal : 0) + " Soal ")])], 1)]);
    }), 0)]) : _c('ul', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('li', {
      staticStyle: {
        "border-bottom": "3px solid #e83b07"
      }
    }, [_c('b', [_vm._v(_vm._s(data.nama_ujian))]), _c('br'), _c('section', {
      staticClass: "pb-1"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(data.timer) + " Menit ")]), _c('feather-icon', {
      staticClass: "mr-50 ml-2",
      attrs: {
        "icon": "FileTextIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(data !== null && data !== void 0 && (_data$ujian_data = data.ujian_data) !== null && _data$ujian_data !== void 0 && _data$ujian_data.sum_soal ? data === null || data === void 0 ? void 0 : (_data$ujian_data2 = data.ujian_data) === null || _data$ujian_data2 === void 0 ? void 0 : _data$ujian_data2.sum_soal : 0) + " Soal ")])], 1)])])], 1);
  }), 0) : _c('div', [_c('h5', {
    staticClass: "text-danger text-center mt-2"
  }, [_vm._v("Tidak ada Subtest")])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary",
      "header": "Berikut petunjuk pengerjaan",
      "header-bg-variant": "primary",
      "header-text-variant": "white"
    }
  }, [_c('b-card-body', [_vm.data.instruction ? _c('b', {
    domProps: {
      "innerHTML": _vm._s(_vm.data.instruction)
    }
  }) : _c('b', [_c('ol', {
    staticStyle: {
      "padding": "0"
    }
  }, [_c('li', [_vm._v("Tryout ini akan terbagi menjadi beberapa subtes")]), _c('li', [_vm._v(" Sesi dikerjakan sesuai urutan dan dimulai saat kamu menekan tombol ‘Mulai’ ")]), _c('li', [_vm._v(" Sesuai dengan peraturan resmi UTBK 2023, kamu hanya dapat mengerjakan tiap komponen dalam durasi yang tersedia ")]), _c('li', [_vm._v(" Setelah mengerjakan semua subtes, pastikan klik tombol “kumpulkan” ")])])])])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }